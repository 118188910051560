<template>
  <div class="container col-ms-12 search">
    <div class="search-box">
<!--      <img src="../assets/img/ico-01.png" alt="search">-->
      <b-form-input v-model="keyword" class="col-sm-6" type="search"
                    placeholder="筛选域名名单，例如，固废处理技术"></b-form-input>
      <b-button @click="search()">搜索</b-button>
    </div>
    <h3 class="search-info">已为您找到相关结果{{items.length}}个</h3>
    <div class="search-result">
      <div v-for="item in items" :key="item.id">
        <a href="javascript:void(0)">{{ item.wasteType }}</a>
        <!--        <div>{{ item.wasteSubtype }}</div>-->
        <p>
          <b-icon icon="x-diamond-fill"></b-icon>
          数据来源：{{ item.source }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {doSearch} from "@/api/data";

export default {
  name: "Search",
  data() {
    return {
      keyword: '',
      items: []
    }
  },
  mounted() {
    // eslint-disable-next-line no-debugger
    // debugger
    this.keyword = this.$route.params.keyword;
    doSearch({keyword: this.keyword})
  },
  methods: {
    search() {
      doSearch({keyword: this.keyword}).then(res => {
        const {data} = res;
        this.items = data;
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/search.less";
</style>